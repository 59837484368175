import React, { useEffect } from 'react';
import Router from 'next/router';
import { useDispatch } from 'react-redux';
import Cookie from 'js-cookie';
import { Layout } from '../src/components';
import { logoutUser } from '../src/actions/auth';

const Home = () => {
  const dispatch = useDispatch();
  Cookie.set('access_token', 'RqVtOJqv-ZtRbGOyBf0D6mJzku7uk9YBRQ9TVebHrQ4', {});
  Cookie.set('refresh_token', '7229b845fdd8344780d494e3efe3dbd382f89f41ca1677fea866355efcc33ecc', {});
  Cookie.set('expires_at', '1728896204', {});

  useEffect(() => {
    Cookie.get('access_token') ? Router.push('/documents') : dispatch(logoutUser());
  }, []);

  return null;
};

Home.getLayout = page => <Layout>{page}</Layout>;

export default Home;
